<template>
  <div>
    <button
      class="btn btn-default btn-sm mt-4 px-5"
      @click="this.$router.go(-1)"
    >
      Back
    </button>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card shadow-lg bg-gradient-secondary border-0 mb-0">
          <div class="card-body px-lg-5 py-lg-5 row">
            <div class="col-md-12">
              <h2 class="mb-2">Update Entity information</h2>
              <h4 class="">Business Information</h4>
              <hr class="my-0 mb-3" />
              <div class="ml-3 row">
                <div class="form-group col-md-6">
                  <label class="form-control-label"
                    >Business name </label
                  >
                  <span class="ml-1 text-danger">*</span>
                  <input
                    class="form-control form-control-alternative"
                    placeholder="name of the business"
                    type="text"
                    v-model="post_data.name"
                  />
                  <label
                    v-for="error in errors.name"
                    :key="error"
                    class="form-control-flush text-danger text-sm"
                    >{{ error }}</label
                  >
                </div>
                <div class="form-group col-md-6">
                  <label class="form-control-label">Email</label>
                  <span class="ml-1 text-danger">*</span>
                  <input
                    class="form-control form-control-alternative"
                    placeholder="email"
                    v-model="post_data.email"
                    type="email"
                  />
                  <label
                    v-for="error in errors.email"
                    :key="error"
                    class="form-control-flush text-danger text-sm"
                    >{{ error }}</label
                  >
                </div>
                <div class="form-group col-md-6">
                  <label class="form-control-label">Phone Number</label>
                  <span class="ml-1 text-danger">*</span>
                  <input
                    class="form-control form-control-alternative"
                    placeholder="phone number"
                    v-model="post_data.phone"
                    type="text"
                  />
                  <label
                    v-for="error in errors.phone"
                    :key="error"
                    class="form-control-flush text-danger text-sm"
                    >{{ error }}</label
                  >
                </div>
                <div class="form-group col-md-6">
                  <label class="form-control-label"
                    >Address</label
                  >
                  <span class="ml-1 text-danger">*</span>
                  <input
                    class="form-control form-control-alternative"
                    placeholder="Address"
                    v-model="post_data.address"
                    type="text"
                  />
                  <label
                    v-for="error in errors.address"
                    :key="error"
                    class="form-control-flush text-danger text-sm"
                    >{{ error }}</label
                  >
                </div>
               
                <div class="form-group col-md-6">
                  <label class="form-control-label"
                    >Business website url</label
                  >
                  <input
                    class="form-control form-control-alternative"
                    placeholder="Enter the website URL"
                    v-model="post_data.website"
                    type="text"
                  />
                  <label
                    v-for="error in errors.website"
                    :key="error"
                    class="form-control-flush text-danger text-sm"
                    >{{ error }}</label
                  >
                </div>
                <div class="form-group col-md-6">
                  <label class="form-control-label"
                    >Business category</label
                  >
                  <span class="ml-1 text-danger">*</span>
                  <category-multiselect
                    :categoriesList="categories"
                    @selected="getSelectedCategories"
                    :oldSelected="post_data.categories"
                  />
                  <label
                    v-for="error in errors.category"
                    :key="error"
                    class="form-control-flush text-danger text-sm"
                    >{{ error }}</label
                  >
                </div>

                <div class="form-group col-md-12">
                  <label class="form-control-label"
                    >Business description.</label
                  >
                  <span class="ml-1 text-danger">*</span>
                  <rich-text
                    id="entity_about"
                    @change="handle_entity_about"
                    :text="about"
                  />
                  <label
                    v-for="error in errors.about"
                    :key="error"
                    class="form-control-flush text-danger text-sm"
                    >{{ error }}</label
                  >
                </div>
              </div>
            </div>
          
            <div class="col-md-6">
                <button class="btn btn-secondary px-5" @click="this.$router.go(-1)">
                  Cancel
                </button>
                <button class="btn btn-default px-5" @click="update_entity">
                  Update
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RichText from "../../components/RichTextEditor.vue";
import { alert } from "../../utils/alertUtils";
import CategoryMultiSelect from "../../components/CategoryMultiSelect.vue";
export default {
  name: "UpdateEntity",
  components: {
    "rich-text": RichText,
    'category-multiselect': CategoryMultiSelect,
  },
  data() {
    return {
      business_orientation: "",
      about: "",
      description: "",
      post_data: {
        business_type:"Both",
        target_market:"Both"
      },
      industries: [],
      categories:[],
      errors: {
        description: [],
      },
      years: [],
    };
  },
  watch: {
    business_orientation: function (new_v) {
      if (new_v === "service") {
        this.post_data.is_product_related = false;
        this.post_data.is_service_related = true;
      } else if (new_v === "product") {
        this.post_data.is_product_related = true;
        this.post_data.is_service_related = false;
      } else if (new_v === "both") {
        this.post_data.is_product_related = true;
        this.post_data.is_service_related = true;
      }
    },
  },
  methods: {
    get_years: function () {
      for (
        var i = new Date().getFullYear();
        i > new Date().getFullYear() - 200;
        i--
      ) {
        this.years.push(i);
      }
    },
    handle_entity_description: function (description) {
      this.post_data.description = description;
    },
    handle_entity_about: function (about) {
      this.post_data.about = about;
      this.post_data.description = about;
    },
    get_entity: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.post_data.name = res.data.name;
          this.post_data.email = res.data.email;
          this.post_data.phone = res.data.phone;
          this.post_data.address = res.data.address;
          this.post_data.website = res.data.website;
          this.post_data.description = res.data.description;
          this.post_data.about = res.data.about;
          this.post_data.no_of_products = res.data.no_of_products;
          this.post_data.no_of_services = res.data.no_of_services;
          this.post_data.no_of_employees = res.data.no_of_employees;
          this.post_data.no_of_women_employees = res.data.no_of_women_employees;
          this.post_data.is_rdb_registered = res.data.is_rdb_registered;
          this.post_data.target_market = res.data.target_market;
          this.post_data.has_rbs_certificate = res.data.has_rbs_certificate;
          this.post_data.does_delivery = res.data.does_delivery;
          this.post_data.accepted_electronic_payments =
            res.data.accepted_electronic_payments;
          this.post_data.business_type = res.data.business_type;
          this.post_data.starting_year = res.data.starting_year;
          this.post_data.industry = res.data.industry
            ? res.data.industry.uuid
            : "N/A";
          this.description = res.data.description;
          this.about = res.data.about;
          this.post_data.is_service_related = res.data.is_service_related;
          this.post_data.is_product_related = res.data.is_product_related;
          this.post_data.categories=res.data.categories
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_industries: function () {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/industries`)
        .then((res) => {
          this.industries = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSelectedCategories:function(evt){
      this.post_data.categories=evt
    },

    update_entity: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}`;
      this.post_data.target_market="Both"
      this.post_data.business_type="Both"
      axios
        .put(url, this.post_data, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then(() => {
          alert.success_center("Business Updated Successfully.");
          this.$router.push({
            name: "EntityDetail",
            params: { uuid: this.$route.params.uuid },
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.errors = err.response.data;
            console.log(err.response);
            alert.error("An error occured, check the form for specific erros!");
          } else {
            console.log(this.post_data);
            console.log(err.response);
            alert.error(
              "An error occured please try again,if the error persist kindly contact the owner of this site!"
            );
          }
        });
    },
    fetch_categories: function () {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/parentCategories`)
        .then((res) => {
          console.log(res);
          this.categories=res.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  created() {
    this.get_entity();
    this.get_years();
    this.get_industries();
    this.fetch_categories()
  },
};
</script>