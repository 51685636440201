<template>
   <div>
      <div class="d-flex " @click="showCategorySelect">
         <div class="form-control ">
          <span  v-for="catVal in selected" :key="catVal">
            <label class="badge badge-darker m-1">{{ catVal }}</label>
          </span>
        </div>
        <div class="form-control" style="width: 7%;">
          <span class="fa fa-chevron-down align-items-end"></span>
        </div>
      </div> 
      <div class="shadow card p-2 category-select" v-if="showCategories">
        <div>
            <input type="text" class="form-control" @keyup="onSearch($event)"  />
        </div>
        <div class="category-list-selector">
          <div v-for="category in resultCategories" :key="category.name" >
            <input type="checkbox" :value="category.name" :checked="oldSelected.includes(category.name)" :id="category.name.replaceAll(' ','_')" @click="onCatSelect($event,category)">
            <label>&nbsp;{{ category.name }}</label>
          </div> 
        </div>
        
      </div>
   </div>
</template>
<script>
  export default {
    name:"CategoryMultiSelect",
  
    props:{
      categoriesList:{
            type: Array,
            required: true,
            default:()=>[]
      },
      oldSelected:{
        type: Array,
        required: false,
        default:()=>[]
      }
    },
    data(){
        return{
          resultCategories:[],
          selected:[],
          showCategories:false
        }
    },
    watch:{
      oldSelected:function(){
        this.selected=this.oldSelected
      }
    },
    methods:{
        onCatSelect:function(evt,category){
          if(evt.target.checked){
            this.selected.push(category.name)
          }else{
            this.selected= this.selected.filter(el=> el!==category.name)
          }
          this.$emit('selected',this.selected)
       },
       onSearch:function(evt){
        this.resultCategories=[]
        if(evt.target.value.length>0){
            this.categoriesList.forEach(el => {
            if(el.name.toLowerCase().includes(evt.target.value.toLowerCase())){
                this.resultCategories.push(el)
            } 
         })
        }else{
            this.resultCategories=this.categories
        }
       },
       showCategorySelect:function(){
          if(!this.showCategories){
            this.resultCategories=this.categoriesList
            this.showCategories=true;
          }else{
            this.resultCategories=[]
            this.showCategories=false
          }
       }
    },

  }
</script>
<style>
.category-select{
  position: absolute;
  z-index: 1500;
  width: 100%;
}
.category-list-selector{
  height: 300px;
  overflow: scroll;
}

</style>